<template>
  <div class="home-container">
    Survey
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({})
};
</script>

<style scoped>
  .home-container {
    display: flex;
    flex: 1;
    margin-top: 20%;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: #1565c0!important;
  }
</style>